import React from 'react';
import style from './index.module.css';

export default ({ options, required, name }) => (
  <div className={style.dropdown}>
    <div className={style.dropdown__wrapper}>
      <select
        className={style.dropdown__select}
        name={name}
        required={required}
      >
        <option value="">Anliegen</option>
        {options &&
          options.map((item, index) => (
            <option
              className={style.dropdown__option}
              key={index}
              value={item.value}
            >
              {item.text}
            </option>
          ))}
      </select>
    </div>
  </div>
);
