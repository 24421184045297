import style from './index.module.css';
import React from 'react';

export default ({ children, name, action }) => {
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  const handleSubmit = e => {
    const formEl = e.target.elements;
    let formData = {};
    for (var i = 0; i < formEl.length; i += 1) {
      if (!(formEl[i].name === 'bot-field' || formEl[i].name === '')) {
        Object.assign(formData, { [formEl[i].name]: formEl[i].value });
      }
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ ...formData }),
    })
      .then(() => {
        window.location.href = '/danke/';
      })
      .catch(error => alert(error));

    e.preventDefault();
  };

  return (
    <form
      onSubmit={handleSubmit}
      action={action}
      name={name}
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      className={style.form}
    >
      <div className={style.form__honeypot}>
        <label>
          Don’t fill this out if you're human:
          <input name="bot-field" />
        </label>
      </div>
      {children}
    </form>
  );
};
