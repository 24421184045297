import style from './index.module.css';
import React from 'react';

export default ({ placeholder, required, name, label }) => (
  <div className={style.textarea}>
    <textarea
      className={style.textarea__field}
      placeholder={placeholder}
      required={required}
      name={name}
      minLength="5"
      rows="10"
      cols="50"
      aria-label={label}
    />
    <span className={style.textarea__label}>{label}</span>
  </div>
);
