import css from './index.module.css';
import React from 'react';

export default ({ value, label, name, type, required, placeholder }) => (
  <div className={css.input}>
    <input
      className={css.input__field}
      value={value}
      aria-label={label}
      name={name}
      type={type}
      required={required}
      placeholder={placeholder}
    />
    <span className={css.input__label}>{label}</span>
  </div>
);
