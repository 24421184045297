import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Input from '../components/Input';
import Form from '../components/Form';
import Button from '../components/Button';
import Textarea from '../components/Textarea';
import Article from '../components/Article';
import Dropdown from '../components/Dropdown';
import RichText from '../components/RichText';
import { graphql } from 'gatsby';

export const pageQuery = graphql`
  {
    contentfulArticle(slug: { eq: "kontakt" }) {
      name
      text {
        json
      }
    }
  }
`;

export default props => (
  <Layout>
    <SEO
      title={props.data.contentfulArticle.name}
      keywords={[`Rebberg Sulzberg`, `Kontakt`]}
    />
    <Article title={props.data.contentfulArticle.name}>
      <RichText document={props.data.contentfulArticle.text.json} />

      <Form action="/danke/" name="Kontakt">
        <Input type="hidden" name="form-name" value="Kontakt" />
        <Input
          required
          name="name"
          type="text"
          label="Name"
          placeholder="Name"
        />
        <Input
          required
          name="email"
          type="email"
          label="E-Mail"
          placeholder="E-Mail"
        />
        <Input name="phone" type="tel" label="Telefon" placeholder="Telefon" />
        <Dropdown
          required
          name="subject"
          options={[
            {
              value: 'schoepfli',
              text: 'Schöpfli',
            },
            {
              value: 'wein',
              text: 'Wein',
            },
            {
              value: 'anderes',
              text: 'Anderes',
            },
          ]}
        />
        <Textarea
          required
          name="message"
          type="text"
          label="Mitteilung"
          placeholder="Mitteilung"
        />
        <Button type="submit">Senden</Button>
      </Form>
    </Article>
  </Layout>
);
